'use strict';

angular.module('enervexSalesappApp').controller('DesignCtrl', function($scope, $stateParams, $state, $q, DesignService, User, Account, Job, Product, FileUploader, _, Auth, Util, Flash, Application, Quote, $uibModal, Design, BuildType, Property, Manufacturer, ApplicationSubtype, LineitemSource, SystemsManager, ModalUtil) {
	$scope.jobLabel = Util.jobLabel;
	$scope.attachments = [];
	$scope.accountId = $stateParams.accountId;
	$scope.jobId = $stateParams.jobId;
	$scope.designId = $stateParams.designId;
	$scope.checkedMembers = [];
	$scope.productsPage = [];
	$scope.isAdmin = Auth.isAdmin;
	$scope.me = Auth.getCurrentUser;
	$scope.isPreviousVersion = ($stateParams.version && $stateParams.version != "") ? true : false;
	$scope.itemTypes = [
		{
			name: 'Existing Product',
			value: 'existing'
		},
		{
			name: 'Custom Product',
			value: 'custom'
		}
	]
	$scope.ctx = {
		showDetails: false
	}
	$scope.$on("$destroy", function() {
		Flash.clear()
	})
	LineitemSource.query().$promise.then(function(result){
		$scope.lineitemSources = result
		$scope.newItem = createNewItem();
	})
	$scope.variableModes = [{
		name: "Variable and Adjustble",
		_id:'variablePlusAdjustable'
	},{
		name: "Adjustable Only",
		_id:'adjustableOnly'
	},{
		name: "Not Set",
		_id:'unset'
	}]
	$scope.configs = Property.configs()
	function getVariableMode() {
		if (!$scope.design){
			return
		}
		if (!$scope.design.stack) {
			$scope.design.stack = {}
		}
		$scope.variableMode = _.find($scope.variableModes, function(o){
			return o._id == $scope.design.stack.variableMode
		})
	}

	ApplicationSubtype.query().$promise.then(function(result){
		$scope.allApplicationSubtypes = result
	})
	function filterApplicationSubtypes() {
		var applicationId = $scope.design.application && $scope.design.application._id
		$scope.applicationSubtypes = _.filter($scope.allApplicationSubtypes, function(o){
			try {
				return o.application._id == applicationId
			}catch(e) {
				return false
			}
		})
		$scope.applicationSubtype = _.find($scope.applicationSubtypes, function(o){
			return $scope.design.applicationSubtype == o._id
		})
	}
	Manufacturer.query().$promise.then(function(result){
		$scope.manufacturers = result
		$scope.manufacturer = _.find(result, function(o){
			return o.isDefault
		})
	})
	$scope.includesDisabled = function() {
		// if (!application) {
		// 	return true
		// }
		// var result = application.properties.stackOnly ? true : false
		// return result
		return false;
	}
	$scope.loadDesign = function(design) {
		$scope.design = design;
		// $scope.design.application = (design.application) ? design.application._id : null;
		$scope.design.applicationSubtype = (design.applicationSubtype) ? design.applicationSubtype._id : null;
		$scope.design.buildType = (design.buildType) ? design.buildType._id : null;
		$scope.target = $scope.design;
		checkQuotes()
		Util.setNotifyMembersType($scope.design, $scope.job)
		$scope.checkedMembers = design.explicitMembers;
		Util.checkSystems($scope.design, $scope.lineitemSources)
		if ($stateParams.version != undefined) {
			var version = Number($stateParams.version);
			DesignService.fetchDesignVersions().$promise.then(function(res){
				$scope.designVersions = res;
			})
		}
		$scope.selectApplication();
		$scope.calcTotal();
		getBuildType()
		getVariableMode()
		if ($stateParams.dirty == "true") {
			$scope.ctx.formChanged = true
		}
	}
	$scope.exportLineitems = function() {
		var url = "/api/accounts/" + $scope.accountId + "/jobs/" + $scope.jobId + "/designs/" + $scope.designId +"/export-lineitems";
		window.location.href = url;
	}
	$scope.designUploader = new FileUploader({});
	$scope.importItems = function() {
		_.each($scope.designUploader.queue, function(data){
			var fd = new FormData();
			fd.append('name', data._file.name);
			fd.append('asset', data._file);
			Flash.clear()
			Design.importItems({
				accountId: $stateParams.accountId,
				jobId: $stateParams.jobId,
				id: $stateParams.designId,
			}, fd).$promise.then(function(design){
				$scope.designUploader.clearQueue()
				$scope.loadDesign(design)
			}).catch(function(e){
				var message = "";
				if (e.data ) {
					message = message + e.data.message
				} else {
					message = message + e
				}
				Flash.create('danger', '<strong>Failure!</strong> ' + message, 0, {}, true);
				$scope.designUploader.clearQueue()
			})
		});
	}
	$scope.selectApplication = function() {
		if (!$scope.design){
			return;
		}
		var applicationId = $scope.design.application && $scope.design.application._id
		$scope.application = _.find($scope.applications, function(o){
			return o._id == applicationId
		})
		filterApplicationSubtypes()
	}
	function setup() {
		$scope.autoCompleteOptions = {
			minimumChars: 1,
			dropdownWidth: "500px",
			data: function (term) {
				var params = {
					limit:20,
					term: term,
					manufacturer: $scope.manufacturer._id
				};

				if ($scope.configs.allow_deleted_manual_products != 'true'){
					params.deleted = false;
				}
				// params.isAggregate = 'false'
				return Product.query(params).$promise.then(function(products){
					$scope.productsPage = products
					return _.map(products, function(product){
						return product.navId + " - " + product.name + " " + accounting.formatMoney(product.price)
					});
				})
			},
			itemSelected: function(item){
				var fullProduct = _.find($scope.productsPage, function(product){
					return (product.navId + " - " + product.name + " " + accounting.formatMoney(product.price)) == item.item
				})
				$scope.newItem.product = fullProduct;
				fullProduct.formattedPrice = accounting.formatMoney(fullProduct.price);
			}
		}
		$scope.buildTypes = BuildType.query()
		$scope.account = Account.get({
			id: $stateParams.accountId
		});
		Job.get({
			id: $stateParams.jobId,
			accountId: $stateParams.accountId
		}).$promise.then(function(job){
			$scope.job = job
			$scope.permissions = Auth.jobPermissions($scope.job, $scope.configs)

			$scope.isInternalMember = Auth.isInternalMember($scope.job);
			if ($stateParams.designId != "new"){
				fetchComments();
				fetchDesign();
				getDesignVersions();
			} else {
				if ($stateParams.copyDesignId){
					Design.version({
						accountId: $stateParams.accountId,
						jobId: $stateParams.jobId,
						id: $stateParams.copyDesignId,
						version: $stateParams.copyVersion
					}).$promise.then(function(design){
						$scope.design = {
							name: "Copy of "+ design.name,
							items: design.items,
							fc5layout: design.fc5layout,
							bomResponse: design.bomResponse,
							calcResult: design.calcResult,
							application: design.application,
							faults: design.faults,
							voltage: design.voltage,
							supVoltage: design.supVoltage,
							control: design.control,
							supFan: design.supFan,
							exhFan: design.exhFan,
							damper: design.damper,
							baffles: design.baffles,
							modType: design.modType,
							supFanOptions: design.supFanOptions,
							exhFanOptions: design.exhFanOptions,
							units: design.units,
							viewMode: design.viewMode,
							buildType: design.buildType,
							cloneOf: {
								version: $stateParams.copyVersion,
								design: $stateParams.copyDesignId
							},
							include:design.include,
							stack: design.stack,
							designImage: design.designImage,
							backgroundColor: design.backgroundColor,
							gridDotColor: design.gridDotColor,
							selectionColor: design.selectionColor,
							lineColor: design.lineColor,
							lineTriangleColor: design.lineTriangleColor,
							warningColor: design.warningColor,
							triangleFont: design.triangleFont,
							triangleFontColor: design.triangleFontColor,
							triangleBorderColor: design.triangleBorderColor,
							triangleLength: design.triangleLength,
							economizer: design.economizer,
							notifyMembers:[],
							visibility:Util.defaultVisibility($scope.job)
						}
						Util.checkSystems($scope.design, $scope.lineitemSources)
						$scope.selectApplication();
						$scope.target = $scope.design;
						Util.setNotifyMembersType($scope.design, $scope.job);
						$scope.ctx.formChanged = true;
					});
				} else {
					$scope.design = {
						notifyMembers:[],
						visibility:Util.defaultVisibility($scope.job),
						units: 'IP',
						viewMode: '3D',
						include:{
							stack:false,
							exhaust:true
						},
						application: {}
					}
					Util.checkSystems($scope.design, $scope.lineitemSources)
					$scope.target = $scope.design;
					Util.setNotifyMembersType($scope.design, $scope.job)
				}
			}
		});
		$scope.mode = $stateParams.mode;
		if ($scope.mode === 'edit'){
			$scope.editForm = true;
		}
		if ($stateParams.dirty == "true") {
			$scope.ctx.formChanged = true
		}
		Application.query({}).$promise.then(function(applications){
			$scope.applications = _.filter(applications, function(application){
				return application.active
			});
			$scope.selectApplication();
		})
		$scope.cmbUnits = {
			options: [{
				text: "IP - Standard",
				value: "IP"
			}, {
				text: "SI - Metric",
				value: "SI"
			}]
		}
		$scope.viewModeOptions = ["2D", "3D"];
		$scope.checkedEmailMembers = [];
		$scope.setChecks = false;
		$scope.newComment = {
			notifyMembers:[]
		};
		Util.setNotifyMembersType($scope.newComment, $scope.job)
		var uploader = $scope.uploader = new FileUploader({});
		$scope.newItem = createNewItem();
	}
	setup()
	$scope.createClone = function() {
		$state.go("design", {
			accountId: $stateParams.accountId,
			jobId: $stateParams.jobId,
			designId: "new",
			copyVersion: $scope.design.version,
			copyDesignId: $scope.design._id
		}, {
			reload: true,
			inherit: false
		})
	}
	$scope.promoteVersion = function() {
		Design.promoteVersion({
				accountId: $stateParams.accountId,
				jobId: $stateParams.jobId,
				designId: $stateParams.designId,
				version: $stateParams.version
		}).$promise.then(function() {
			$state.go('design', {
				accountId: $stateParams.accountId,
				jobId: $stateParams.jobId,
				designId: $stateParams.designId,
				version:null
			}, {
				reload: true,
				inherit: false
			})
		})
	}
	$scope.updateQuote = function() {
		$state.go('quote', {
			accountId: $scope.accountId,
			jobId:$scope.jobId,
			quoteId: $scope.existingQuote._id,
			designId: $scope.designId,
			mode:"edit"
		}, {
			reload: true,
			inherit: false
		});
	}
	$scope.createQuote = function() {
		$state.go('quote', {
			accountId: $scope.accountId,
			jobId:$scope.jobId,
			quoteId: "new",
			designId: $scope.designId,
			mode:"edit"
		}, {
			reload: true,
			inherit: false
		});
	}
	$scope.viewQuote = function() {
		$state.go('quote', {
			accountId: $scope.accountId,
			jobId:$scope.jobId,
			quoteId: $scope.existingQuote._id,
			designId: $scope.designId,
			mode:""
		}, {
			reload: true,
			inherit: false
		});
	}
	function checkQuotes() {
		$scope.existingQuote = null;
		$scope.existingDesign = null;
		$scope.canUpdateQuote = false;
		$scope.canCreateQuote = false;
		$scope.canViewQuote = false;
		if (!$scope.design){
			return
		}
		Quote.query({
			accountId: $scope.accountId,
			jobId: $scope.jobId
		}).$promise.then(function(quotes){
			$scope.existingQuote = _.find(quotes, function(quote){
				$scope.existingDesign =  _.find(quote.designs, function(design){
					return design.design && design.design._id == $scope.design._id
				})
				return $scope.existingDesign;
			})
			$scope.canCreateQuote = ($scope.existingQuote) ? false : true;
			$scope.canUpdateQuote = ($scope.existingDesign && $scope.existingDesign.version != $scope.design.version) ? true : false;
			$scope.canViewQuote = ($scope.existingDesign && $scope.existingDesign.version == $scope.design.version) ? true : false;
		})
	}
	$scope.editState = function(){
		// $stateParams.mode = 'edit';
		$state.go('design', {
			accountId: $scope.accountId,
			jobId:$scope.jobId,
			designId: $scope.designId,
			mode: 'edit'
		}, {
			reload: true,
			inherit: false
		});
	}
	$scope.goToDrawing = function(design){
		$state.go('drawing', {
			accountId: $scope.accountId,
			jobId:$scope.jobId,
			designId: $scope.designId,
			version: $stateParams.version
		}, {
			reload: true,
			inherit: false
		});
	}
	$scope.previousVersion = function(version){
		var latestV = _.max($scope.designVersions, function(design){
			return design.version;
		});

		if (version != latestV) {
			$state.go('design', {
				accountId: $stateParams.accountId,
				jobId: $stateParams.jobId,
				designId: $stateParams.designId,
				version: version.version,
				mode: 'version'
			}, {
				reload: true,
				inherit: false
			});
		} else {
			$state.go('design', {
				accountId: $stateParams.accountId,
				jobId: $stateParams.jobId,
				designId: $stateParams.designId,
				version: null
			}, {
				reload: true,
				inherit: false
			});
		}
	}
	function fetchDesign(){
		DesignService.getDesign().$promise.then(function(design){
			$scope.loadDesign(design)
		});
	}
	$scope.calcTotal = function() {
		$scope.totalPrice = 0;
		$scope.totalCount =0;

		_.each($scope.design.items, function(item, index){
			var line = Util.totalLineitem(item)
			$scope.totalPrice = $scope.totalPrice + line.totalPrice
			$scope.totalCount = $scope.totalCount + line.totalCount
			// $scope.totalCountStack = $scope.totalCountStack + line.totalCountStack
			// $scope.totalPriceStack = $scope.totalPriceStack + line.totalPriceStack
			// $scope.totalCountExhaust = $scope.totalCountExhaust + line.totalCountExhaust
			// $scope.totalPriceExhaust = $scope.totalPriceExhaust + line.totalPriceExhaust
			// $scope.totalCountSupply = $scope.totalCountSupply + line.totalCountSupply
			// $scope.totalPriceSupply = $scope.totalPriceSupply + line.totalPriceSupply
			// $scope.totalCountOther = $scope.totalCountOther + line.totalCountOther
			// $scope.totalPriceOther = $scope.totalPriceOther + line.totalPriceOther
			// console.log("items[" + index + "] totalPrice: "+ $scope.totalPrice)
		})
	}
	function getDesignVersions(){
		DesignService.fetchDesignVersions().$promise.then(function(res){
			$scope.designVersions = res;
		})
	}
	$scope.unremoveProduct = function(lineitem){
		lineitem.removed = false;
		$scope.calcTotal();
	}
	$scope.removeProduct = function(lineitem){
		$scope.ctx.formChanged = true;
		if (lineitem.origin == "manual"){
			var index = $scope.design.items.indexOf(lineitem);
			if (index > -1){
				$scope.design.items.splice(index, 1);
			}
		} else {
			lineitem.removed = true
		}
		$scope.calcTotal()
	}

	function createNewItem(previousItem) {
		if (previousItem) {
			var source = {}
			var previousSystemSouce = previousItem.systemSources[0]
			var previousSouce = previousItem.source
			var systemSource = {
				systemId: previousSystemSouce.systemId,
				lineitemSource: previousSystemSouce.lineitemSource
			}
			_.each($scope.lineitemSources, function(o){
				source[o.code] = 0
				systemSource[o.code] = 0
			})
			return {
				itemType: previousItem.itemType,
				quantity: 1,
				uom: "EA",
				origin: previousItem.origin,
				source: source,
				customProduct: {
					name: "",
					price: 0,
					description: "",
					unit: "EA"
				},
				systemSources: [systemSource]
			}
		} else {
			var source = {}
			var systemSource = {
				systemId: 1,
			}
			_.each($scope.lineitemSources, function(o){
				source[o.code] = 0
				systemSource[o.code] = 0
			})
			return {
				itemType: "existing",
				quantity: 1,
				uom: "EA",
				origin: 'manual',
				source: source,
				customProduct: {
					name: "",
					price: 0,
					description: "",
					unit: "EA"
				},
				systemSources: [systemSource]
			}
		}
	}
	$scope.validateProduct = function(item) {
		var newItem = item ? item : $scope.newItem
		Flash.clear()
		var messages = []
		if (!newItem.systemSources[0].lineitemSource) {
			messages.push("Please select a source")
		}
		if (newItem.itemType == "existing") {
			if (!newItem.product) {
				messages.push("Please select a product")
			}
		}
		if (messages.length > 0) {
			var message = "<strong>Error!</strong> Cannot add line item"
			_.each(messages, function(o, index){
				message = message + '<div>'+ o+ '</div>'
			})
			Flash.create('danger', message, 0, {}, true);
			return false
		}
		return true
	}
	$scope.addQuantity = function(lineitem, amount) {
		if (lineitem.quantity + amount > 0) {
			lineitem.quantity = lineitem.quantity + amount
			$scope.changeQuantity(lineitem)
			$scope.calcTotal()
			$scope.itemAdded()
		}
	}
	$scope.itemAdded = function() {
		$scope.ctx.formChanged = true;
	}
	$scope.canEditQuantity = function(lineitem) {
		if (!$scope.editForm) {
			return false
		}
		if (lineitem.origin == "manual") {
			//check to see that not multiple sources
			var filtered = _.filter($scope.lineitemSources, function(o){
				return lineitem.source[o.code] > 0
			})
			return filtered.length <= 1 && lineitem.systemSources.length == 1
		} else {
			return false
		}
	}
	$scope.changeQuantity = function(lineitem) {
		var lineitemSource = _.find($scope.lineitemSources, function(o){
			return (lineitem.source[o.code] > 0) 
		})
		lineitem.systemSources[0][lineitemSource.code] = lineitem.quantity
		lineitem.source[lineitemSource.code] = lineitem.quantity
	}
	$scope.addProduct = function(item){
		//validate item
		if (!$scope.validateProduct(item)) {
			return
		}
		$scope.ctx.formChanged = true;
		var lineitemSource = item.systemSources[0].lineitemSource
		item.source[lineitemSource] = item.quantity
		item.systemSources[0][lineitemSource] = item.quantity
		$scope.newItem = createNewItem(item);
		delete item.systemSources[0].lineitemSource
		$scope.design.items.push(item);
		$scope.calcTotal()
		$scope.clearForm()
	}
	$scope.clearForm = function(){
		$scope.newItem = createNewItem();
	}
	// $scope.checkProducts = function() {
		// if ($scope.productsToAdd[$scope.productsToAdd.length - 1].product != '') {
		// 	$scope.productsToAdd.push({
		// 		quantity: null,
		// 		product: ''
		// 	});
		// }
	// }
	// $scope.removeNewProduct = function(product){
	// 	var index = $scope.productsToAdd.indexOf(product);
	// 	if (index > -1){
	// 		$scope.productsToAdd.splice(index, 1);
	// 	}
	// 	if ($scope.productsToAdd.length === 0) {
	// 		$scope.productsToAdd.push({
	// 			quantity: null,
	// 			product: ''
	// 		});
	// 	}
	// }
	$scope.toggleCheck = Util.toggleCheck;
	$scope.memberOf = Util.memberOf;
	$scope.toggleEmailCheck = function(member){
		$scope.ctx.formChanged = true;
		var memberId = member.user._id
		if ($scope.checkedEmailMembers.indexOf(memberId) === -1){
			$scope.checkedEmailMembers.push(member.user._id);
		} else {
			$scope.checkedEmailMembers.splice($scope.checkedEmailMembers.indexOf(memberId), 1);
		}
	}
	$scope.selectAllEmails = function(){
		$scope.checkedEmailMembers = [];
		_.each($scope.job.members, function(member){
			$scope.checkedEmailMembers.push(member.user._id);
		})
		$scope.setChecks = true;
	}
	$scope.deselectAllEmails = function(){
		$scope.checkedEmailMembers = [];
		$scope.setChecks = false;
	}
	$scope.saveDesignChanges = function(design){
		Flash.clear()
		if (!($scope.design.application && $scope.design.application._id)){
			Flash.create('danger', '<strong>Failure!</strong> Please select an application', 0, {}, true);
			return
		}
		try {
			var application = getApplication()
			Util.validateInclude($scope.design, application)
		} catch(e) {
			Flash.create('danger', '<strong>Failure!</strong> ' + e.message, 0, {}, true);
			return;
		}
		if (design.visibility === 'explicit'){
			design.explicitMembers = $scope.checkedMembers;
		} else {
			design.explicitMembers = [];
		}
		DesignService.updateDesignDetails(design).$promise.then(function(res){
			$state.go('design', {
				accountId: $scope.accountId,
				jobId: $scope.jobId,
				designId: res._id,
				mode: null
			}, {
				reload:true,
				inherit: false
			});
			// $scope.formChanged = false;
		});
	}
	function getApplication() {
		return _.find($scope.applications, function(a){
			return a._id == ($scope.design.application && $scope.design.application._id)
		})
	}
	$scope.createDesign = function() {
		Flash.clear()
		var application = getApplication()
		if (!application){
			Flash.create('danger', '<strong>Failure!</strong> Please select an application', 0, {}, true);
			return
		}
		try {
			Util.validateInclude($scope.design, application)
		} catch(e) {
			Flash.create('danger', '<strong>Failure!</strong> ' + e.message, 0, {}, true);
			return;
		}

		$scope.design.explicitMembers = $scope.checkedMembers;
		if ($scope.design.notifyMembersType === 'all'){
			$scope.design.notifyMembers = _.map($scope.job.members, function(member){
				return member.user._id;
			});
		} else if ($scope.design.notifyMembersType === 'some') {
			$scope.design.notifyMembers = $scope.checkedEmailMembers;
		} else if ($scope.design.notifyMembersType === 'none'){
			$scope.design.notifyMembers = [];
		}
		DesignService.newDesign($scope.design).$promise.then(function(res) {
			if (application.computeAs == "DRY" || application.computeAs == "COM" || application.computeAs == "BWH"){
				$state.go("drawing", {
					accountId: $scope.accountId,
					jobId: $scope.jobId,
					designId: res._id
				}, {
					reload: true,
					inherit: false
				})
			} else {
				$state.go("design", {
					accountId: $scope.accountId,
					jobId: $scope.jobId,
					designId: res._id,
					mode: "edit"
				}, {
					reload: true,
					inherit: false
				})
			}
		});
	}
	function checkStackOnly() {
		if (!$scope.design.application) {
			return
		}
		var application = getApplication()
		if (!application) {
			return
		}
		// if (application.properties.stackOnly) {
		// 	$scope.design.include.stack = true
		// 	$scope.design.include.supply = false
		// 	$scope.design.include.exhaust = false
		// } else {
		// 	if ($scope.design.include.exhaust == false) {
		// 		$scope.design.include.supply = false
		// 	}
		// }
		if (application && application.code == "COM") {
			$scope.design.include.stack = false
			$scope.design.include.supply = true
			$scope.design.include.exhaust = true
		}
		if (application && application.computeAs == "none") {
			$scope.design.include.stack = true
			$scope.design.include.supply = true
			$scope.design.include.exhaust = true
		}
	}
	$scope.change = function() {
		$scope.ctx.formChanged = true
		checkStackOnly()
		filterApplicationSubtypes()
		getBuildType()
		getVariableMode()
	}
	$scope.changeApplication = function() {
		$scope.change(); 
		$scope.selectApplication(); 

		if ($scope.design._id == null) {
			var applicationSubtype = _.find($scope.applicationSubtypes, function(o){
				return o.isDefault
			})
			var buildType = _.find($scope.buildTypes, function(o){
				return o.isDefault
			})
			if ($scope.design.buildType == null && buildType) {
				$scope.design.buildType = buildType._id
			}
			if ($scope.design.applicationSubtype == null && applicationSubtype) {
				$scope.design.applicationSubtype = applicationSubtype._id
			}
		}
	}
	function getBuildType() {
		$scope.buildType = _.find($scope.buildTypes, function(o){
			return $scope.design.buildType == o._id
		})
	}
	$scope.deleteDesign = function(design){
		var parentElem = angular.element(document.querySelector('#design-main'));
		$uibModal.open({
			templateUrl: 'app/main/shared/confirm.html',
			scope: $scope,
			appendTo: parentElem,
			controller: 'ConfirmCtrl',
			size: 'sm',
			resolve: {
				title: function() {
					return "Delete Design"
				},
				body: function() {
					return "Are you sure you want to delete the design?"
				}
			}
		}).result.then(function(confirmed) {
			if (confirmed){
				DesignService.deleteDesign(design).$promise.then(function(res){
					$state.go('designs', {
						accountId: $scope.accountId,
						jobId:$scope.jobId,
					}, {
						reload: true,
						inherit: false
					});
				})
			} else {
				$scope.confirmDelete = false;
			}
		}).catch(function(){
			$scope.confirmDelete = false;
		});
	}
	$scope.cancelDesignEdit = function(){
		// $scope.editForm = !$scope.editForm;
		// fetchDesign();
		// $scope.mode = '';
		$state.go('design', {
			accountId: $scope.accountId,
			jobId: $scope.jobId,
			designId: $scope.designId
		}, {
			reload: true,
			inherit: false
		});
		// $scope.formChanged = false;
	}
	function fetchComments(){
		DesignService.getComments().$promise.then(function(designComments){
			$scope.designComments = designComments;
		});
	}
	$scope.submitComment = function(newComment) {
		if (newComment.notifyMembersType === 'all'){
			newComment.notifyMembers = _.map($scope.job.members, function(member){
				return member.user._id;
			});
		} else if (newComment.notifyMembersType === 'some' ) {
			newComment.notifyMembers = $scope.checkedEmailMembers;
		} else if (newComment.notifyMembersType === 'none'){
			newComment.notifyMembers = [];
		}
		if ($scope.uploader.queue.length > 0) {
			var promises = [];
			_.each($scope.uploader.queue, function(data){
				var fd = new FormData();
				fd.append('name', data._file.name);
				fd.append('asset', data._file);
				fd.append('visibility', $scope.design.visibility);
				promises.push(DesignService.createAttachment(fd).$promise)
			});
			$q.all(promises).then(function(responses){
				newComment.attachments = responses;
				DesignService.createComment(newComment).$promise.then(function(res) {
					$scope.newComment = {
						notifyMembers:[]
					};
					Util.setNotifyMembersType($scope.newComment, $scope.job)
					$scope.uploader.queue = [];
					fetchComments();
				});
			})
		} else {
			DesignService.createComment(newComment).$promise.then(function(res) {
				$scope.uploader.queue = [];
				$scope.newComment = {
					notifyMembers:[]
				};
				Util.setNotifyMembersType($scope.newComment, $scope.job)
				fetchComments();
			});
		}
	}
	$scope.deleteComment = function(comment) {
		DesignService.removeComment(comment).$promise.then(function(res){
			$scope.attachments = [];
			fetchComments();
		})
	}
	$scope.saveCommentChange = function(comment){
		DesignService.updateComment(comment).$promise.then(function(res){
			fetchComments();
		});
	}
	$scope.deleteAttachment = function(comment, attachment){
		var index = comment.attachments.indexOf(attachment)
		if (index > -1){
			comment.attachments.splice(index, 1);
		};
		var newAttachmentList = comment.attachments;

		DesignService.updateComment(comment).$promise.then(function(res){
			fetchComments();
		});
	}
	$scope.openLineitemModal = function() {
		ModalUtil.modalBuilder('#design-main', 'components/design/add-lineitem-modal.html', 'AddLineitemModalCtrl', {
			addProduct: null,
			isApplicationSetting: false
		}, $scope, 'lg')
	}
	$scope.openCustomLineitemModal = function() {
		ModalUtil.modalBuilder('#design-main', 'components/design/add-custom-lineitem-modal.html', 'AddCustomLineitemModalCtrl', {
			addProduct: function() {
				return $scope.addProduct;
			},
			isApplicationSetting: false
		}, $scope, 'lg')
	}
	$scope.openSystems = function() {
		ModalUtil.modalBuilder('#design-main', 'app/main/drawings/systemsModal.html', 'SystemsModalCtrl', {}, $scope, null)
	}
	$scope.systemName = function(systemId) {
		return SystemsManager.systemName(systemId, $scope.design)
	}
	$scope.bomLink = function(rule) {
		return "B-"+rule.id.substring(rule.id.length -5)
	}
	$scope.stackLink = function(rule) {
		return "S-"+rule.id.substring(rule.id.length -5)
	}
	
});
